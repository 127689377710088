<template>
  <div class="row mt-40 align-items-center">
    <div
      @click="checkDone"
      class="col-auto"
    >
      <v-button
        @click="send"
        :disabled="isDisabled"
      >
        {{ content.text }}
      </v-button>
    </div>

    <div
      v-if="showWarning && isDisabled"
      class="col text-color-danger"
    >
      {{ errorText }}
    </div>
  </div>
</template>

<script>
import VButton from '@components/base/VButton/VButton'
import { mapActions } from 'vuex'

export default {
  name: 'ProcessingSubmit',

  components: { VButton },

  inject: [
    'service'
  ],

  props: {
    content: Object
  },

  data () {
    return {
      isModalShown: true
    }
  },

  watch: {
    'content.uuid' () {
      this.setUuid()
    }
  },

  created () {
    this.setUuid()
  },

  computed: {
    /** Проверка на валидность обязательных ответов */
    isDisabled () {
      return this.service.validation.mustPass.length > 0 &&
        !Object.values(this.service.validation.state).every(condition => condition)
    },
    isAllQuestionsAnswered () {
      return Object.values(this.service.validation.filled).every(condition => condition)
    },
    showWarning () {
      return this.service.error.isShow
    },
    errorText () {
      return this.service.error.text
    }
  },

  methods: {
    ...mapActions({
      showModal: 'modal/showModal',
      hideModal: 'modal/hideModal'
    }),

    setUuid () {
      this.service.uuidSubmit = this.content.uuid || null
    },

    send () {
      if (this.content.noAnswerWarning === undefined || this.isAllQuestionsAnswered) {
        this.service.sendSubmitContent()
      }
    },
    /** Проверка на ошибки если кнопка отключена (disabled) */
    checkDone () {
      if (this.content.noAnswerWarning && !this.isDisabled && !this.isAllQuestionsAnswered) {
        this.showModal({
          component: {
            name: 'MSubmitConfirmation',
            props: {
              title: this.$t('modals.submitConfirmation.title'),
              subject: this.$t('modals.submitConfirmation.subject'),
              submitBtnText: this.$t('modals.submitConfirmation.submitBtn'),
              cancelBtnText: this.$t('modals.submitConfirmation.cancelBtn')
            },
            emits: {
              submit: this.confirmModalSubmit
            }
          }
        })
      }

      this.service.error.isShow = this.isDisabled
    },
    confirmModalSubmit () {
      this.service.sendSubmitContent()
    }
  }
}
</script>
