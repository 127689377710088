<template>
  <v-button link>
    <b
        v-clipboard="copyToClipboard"
        v-html="content.text"
    />
  </v-button>
</template>

<script>
import { VButton } from '@components/base'
import { Clipboard } from '@directives'

export default {
  name: 'ProcessingSubmit',

  directives: {
    Clipboard
  },

  components: {
    VButton
  },

  inject: ['service'],

  props: {
    content: Object
  },

  methods: {
    copyToClipboard () {
      this.service.snackbar.show('Вы скопировали ссылку на тест.')
      return window.location.href
    }
  }
}
</script>
