<template>
  <div>
    <section
      v-if="content.text!==''"
      class="row align-items-center justify-content-between mb-8"
    >
      <div class="col-12 col-xl-auto order-xl-1 mb-4 mb-4">
        <v-sheet
          rounded
          elevation="2"
          class="px-16 d-flex align-items-center justify-content-between cursor-pointer"
          @click="showInstruction = !showInstruction"
        >
          <div class="py-16 pr-16">
            <b
              class="text-color-primary"
              v-html="instructionTitle"
            />
          </div>

          <div>
            <v-icon
              stroke="primary"
              name="chevron-2"
              size="16"
              :class="{'rotate-270': showInstruction}"
              class="rotate-90"
            />
          </div>
        </v-sheet>
      </div>

      <article
        class="col-12 order-xl-3 mb-8"
        v-if="showInstruction"
      >
        <v-sheet
          rounded
          elevation="2"
          class="p-16"
        >
          <processing-html :content="content.text"></processing-html>
        </v-sheet>
      </article>

      <div class="col-12 col-xl-auto order-xl-2 mb-4 mt-8 mt-lg-0">
        <v-sheet
          rounded
          elevation="2"
          class="p-16 d-flex align-items-center"
        >
          <div class="time-info">
            <v-icon
              stroke="primary"
              name="clock"
              size="24"
              class="time-info__icon"
            />

            <span class="time-info__text">
              {{ $t("processing.contentTable.info.timerOff") }}
            </span>
          </div>
        </v-sheet>
      </div>
    </section>

    <section class="row row-cols-1">
      <div
        v-for="(row, index) in content.rows"
        :key="index"
        class="col mb-4"
      >
        <v-sheet
          rounded
          :outlined="row.status === 'finished' ? true : null"
          :elevation="row.status !== 'finished' ? 2 : null"
          :class="row.status === 'finished'
              ? 'bg-color-background-1'
              : 'bg-color-background-3'
            "
          class="px-16"
        >
          <div class="row align-items-end align-items-md-center justify-content-between">
            <div class="col-12 col-md-9 col-lg-9 col-xl-10">
              <div class="row align-items-center">
                <div class="col-auto py-8 py-md-16">
                  <span class="h4">{{ index + 1 }}</span>
                </div>

                <div class="col-12 col-md-7 mb-8 mb-md-0 h4">
                  {{ row.text }}
                </div>

                <div
                  v-if="row.timer"
                  class="col-12 col-md-4"
                >
                  <div class="row">
                    <div class="col-auto pr-0">
                      <v-icon
                        stroke="primary"
                        name="clock"
                        size="18"
                        class="time-icon"
                      />
                    </div>
                    <div class="col">
                      {{ row.timer | convertTimestampToTime }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-3 col-lg-3 col-xl-2 text-right py-8">
              <div
                v-if="row.status === 'finished'"
                class="text-nowrap d-flex align-items-center justify-content-end"
              >
                <v-icon
                  name="checked"
                  size="12"
                  stroke="primary"
                  class="mr-8"
                />

                <strong
                  class="text-color-primary"
                  v-html="content.row_finish_text"
                />
              </div>

              <template v-if="row.status === 'button'">
                <v-button @click="startBlock(row.block_name)">
                  <span v-html="content.row_button_text"/>
                </v-button>
              </template>
            </div>
          </div>
        </v-sheet>
      </div>
    </section>
  </div>
</template>

<script>
import VButton from '@/components/base/VButton'
import ProcessingHtml from './ProcessingHtml'
import VSheet from '@components/base/VSheet/VSheet'
import VIcon from '@components/base/VIcon/VIcon'
import convertTimestampToTime from '../../utils/helpers/convertTimestampToTime'

export default {
  name: 'ProcessingContentTable',
  components: {
    VIcon,
    VSheet,
    ProcessingHtml,
    VButton
  },
  inject: ['service'],
  props: {
    content: Object
  },
  data () {
    return {
      showInstruction: false
    }
  },
  filters: { convertTimestampToTime },
  computed: {
    instructionTitle () {
      return this.showInstruction
        ? this.content.hide_instruction_text
        : this.content.show_instruction_text
    }
  },
  methods: {
    startBlock (blockName) {
      this.service.sendContent({
        block_name: blockName
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@styles/variables";
@import "~@styles/tools";

.time-info {
  display: flex;
  align-items: center;

  &__icon {
    margin-right: .5rem;
    opacity: 0.5
  }

  &__text {
    display: inline-block;
  }
}

.time-icon {
  position: relative;
  top: -3px;
}
</style>
