<template>
  <drag-ranging-multiple
    :answers="answers"
    :variants="variants"
    :content="content"
    :error="showError"
    @change="setData"
  />
</template>

<script>
import DragRangingMultiple from '@components/features/DragRaging/DragRangingMultiple'

export default {
  name: 'ProcessingInputMatching',

  components: {
    DragRangingMultiple
  },

  inject: [
    'sendData',
    'service'
  ],

  props: {
    content: Object
  },

  computed: {
    answersCodes () {
      return this.sendData[this.content.code]?.reduce((result, current) => {
        const [groupCode, variantCode] = current.split(';')

        if (result[groupCode]) {
          result[groupCode] = [...result[groupCode], variantCode]
        } else {
          result[groupCode] = [variantCode]
        }

        return result
      }, {})
    },
    variantCodes () {
      return this.answers.reduce((result, current) => {
        return [
          ...result,
          ...current.list.map(({ code }) => code)
        ]
      }, [])
    },
    answers () {
      return this.content.rank_variant.map(group => ({
        code: group.code,
        name: group.text,
        list: this.content.variant.filter(({ code }) => this.answersCodes[group.code]?.includes(code))
      }))
    },
    variants () {
      return this.content.variant.filter(({ code }) => !this.variantCodes.includes(code))
    },

    showError () {
      return this.service.error.isShow && !this.isValid
    },
    checkRMin () {
      return this.answers.every(item => item.list.length >= this.content.r_min)
    },
    checkRMax () {
      return this.answers.every(item => item.list.length <= this.content.r_max)
    },
    getGroupLength () {
      return this.answers.reduce((result, current) => current.list.length + result, 0)
    }
  },

  mounted () {
    this.setValidation()
  },

  methods: {
    /**
     * Устанавливает данные (правой колонки) при change
     * @param {array} group - данные из правой колонки
     * @param {string} group[].code - код колонки или группы
     * @param {string} group[].text - текст колонки или группы
     * @param {array} group[].list - группа с идентичной структурой
     */
    setData (answers) {
      const newAnswers = answers.reduce((result, group) => {
        const items = group.list.map(({ code }) => `${group.code};${code}`)
        return [...result, ...items]
      }, [])

      this.$set(this.sendData, this.content.code, newAnswers)
      this.setValidation()
    },

    setValidation () {
      const { code } = this.content
      console.log('setValidation', this.checkValidation())
      this.service.validation.state[code] = this.checkValidation()
    },

    /**
     * Documentation
     * @link https://ecopsy.atlassian.net/wiki/spaces/LKS/pages/2010939395#%D0%9C%D0%B0%D0%BF%D0%BF%D0%B8%D0%BD%D0%B3
     */
    checkValidation () {
      const { l_min, l_max, r_min, r_max, inactive, required } = this.content

      if (inactive || !required) return true

      const validations = [
        {
          condition: l_min && this.getGroupLength < l_min,
          message: this.$tc('processing.input.matching.error.lMin', l_min)
        },
        {
          condition: l_max && this.getGroupLength > l_max,
          message: this.$tc('processing.input.matching.error.lMax', l_max)
        },
        {
          condition: r_min && !this.checkRMin,
          message: this.$tc('processing.input.matching.error.rMin', r_min)
        },
        {
          condition: r_max && !this.checkRMax,
          message: this.$tc('processing.input.matching.error.rMax', r_max)
        },
        {
          // Если LMin=0, то респондент должен обязательно выбрать хотя бы один вариант ответа не важно в какую категорию.
          condition: !this.getGroupLength,
          message: this.$t('processing.input.matching.error.empty')
        }
      ]

      return validations.every(({ condition, message }) => {
        if (condition) {
          this.service.error.text = message
        }

        return !condition
      })
    }
  }
}
</script>
